import { createStore } from 'redux'

const initialState = {
  role:     0,
  isMobile: false,
  transHeader: true,
  pathname: '',
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case 'updateIsMobile':
      return {
        ...state,
        isMobile: action.isMobile
      };
    case 'updatePathname':
      return {
        ...state,
        pathname: action.pathname
      };
    case 'updateRoleInfo':
      return {
        ...state,
        role: action.role
      };
    case 'setTransHeader':
      return {
        ...state,
        transHeader: action.transHeader
      };
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
